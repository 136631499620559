import { SoSafePageConfigType } from 'shared/models';

type ReturnType = {
  routes: Omit<SoSafePageConfigType, 'label'>[];
  fallbackRoutes: Record<string, string>;
};

export const deepFreeze = <T>(obj: T): T => {
  Object.getOwnPropertyNames(obj).forEach((prop) => {
    const value = (obj as any)[prop];
    if (value && typeof value === 'object' && !Object.isFrozen(value)) {
      deepFreeze(value);
    }
  });
  return Object.freeze(obj);
};

const config = deepFreeze({
  de: {
    production: 'sosafe.de',
    staging: 'sosafe-stage.de',
    development: 'sosafe-dev.de',
    test: 'sosafe-dev.de',
  },
  com: {
    production: 'sosafe-security.com', // TODO: change to sosafe.com when we have a new domain
    staging: 'sosafe-security.com', // TODO: change to sosafe-stage.com when we have a new domain
    development: 'sosafe-security.com', // TODO: change to sosafe-dev.com when we have a new domain
    test: 'sosafe-security.com', // TODO: change to sosafe-dev.com when we have a new domain
  },
});

/**
 * Removes regex patterns from a path, specifically patterns like ':paramName(regex)'.
 * This is useful for cleaning up route paths by removing parameter constraints.
 *
 * @param path - The path string from which regex patterns should be removed.
 * @returns The cleaned path string without regex patterns.
 */
export const removeRegexFromPath = (path: string) =>
  path.replace(/:\w+\([^)]+\)/g, (match) => match.split('(')[0]);

/**
 * Removes the last segment of a path, which is the part after the last '/'.
 * This can be used to derive a base path from a more specific path.
 *
 * @param input - The input path string.
 * @returns The path string without its last segment.
 */
export const removeLastSegment = (input: string): string => {
  const lastSlashIndex = input.lastIndexOf('/');
  return lastSlashIndex !== -1 ? input.substring(0, lastSlashIndex) : input;
};

/**
 * Removes all leading slashes from a string.
 * This is useful for normalizing paths or strings that should not start with a slash.
 *
 * @param input - The input string from which leading slashes should be removed.
 * @returns The string without leading slashes.
 */
export const removeAllSlashesInFront = (input: string): string => input.replace(/^\/+/, '');

/**
 * Extracts the path without parameters and provides a key for the path.
 * The key is derived by removing the last segment of the path.
 *
 * @param input - The input path string.
 * @returns An object containing the path without parameters and a key derived from the path.
 */
export const getPathWithoutParams = (input: string): { value: string; key: string } => ({
  value: input.replace(/^\/:[^/]+/, ''),
  key: removeLastSegment(input),
});

/**
 * Extracts path mappings from a given data object.
 * It processes the data to generate a list of routes and a mapping of fallback routes.
 *
 * @param data - A record containing route configuration data.
 * @returns An object containing an array of route configurations and a record of fallback routes.
 */
export const extractPathMapping = (data: Record<string, any>): ReturnType => {
  const routes: Omit<SoSafePageConfigType, 'label'>[] = [];
  const fallbackRoutes: Record<string, string> = {};

  // Loop through the input data
  for (const [_, entry] of Object.entries(data)) {
    // Ensure required fields exist
    if (entry.path && entry.pageComponentName && entry.pageKey) {
      const path = removeRegexFromPath(entry.path);

      // Map the path to the corresponding componentName and pageKey
      routes.push({
        pageComponentName: entry.pageComponentName,
        pageKey: entry.pageKey,
        path: path.replace(/^\//, ''),
        lazyComponent: entry.lazyComponent,
      });

      // If the entry is marked as an entry route, add it to fallbackRoutes.
      if (entry.isEntryRoute) {
        const { key: routeKey, value } = getPathWithoutParams(path);
        fallbackRoutes[routeKey] = value;
      }
    }
  }

  return { routes, fallbackRoutes };
};

/**
 * Returns the environment-specific domain for a given top-level domain (TLD).
 *
 * @param {'de' | 'com'} tld - The top-level domain to retrieve the config for.
 * @param {string} env - The environment to retrieve the config for.
 * @returns {string} The domain string corresponding to the current DEPLOY_ENVIRONMENT and given TLD.
 */
 const getTLD = (tld: 'de' | 'com', env: string): string => config[tld][env || 'development'];

/**
 * A map of allowed top-level domains and their environment-specific base domains.
 */
const allowedTLDs = (env: string) => ({
  de: getTLD('de', env),
  com: getTLD('com', env),
});

/**
 * Extracts the top-level domain from a given URL and returns the corresponding
 * allowed domain if it's recognized and valid for the current environment.
 *
 * @param {string} url - The URL to parse and extract the domain from.
 * @param {string} env - The environment to retrieve the config for.
 * @returns {string} The allowed domain for the TLD, or 'sosafe.de' if invalid or not allowed.
 */
export const getAppDomain = (url: string, env: string): string => {
  try {
    const { hostname } = new URL(url);
    const parts = hostname.split('.');

    if (parts.length >= 2) {
      return allowedTLDs(env)[parts[parts.length - 1]] || allowedTLDs(env)['de'];
    }
    return allowedTLDs(env)['de'];
  } catch (e) {
    return allowedTLDs(env)['de'];
  }
};

/**
 * Replaces the domain in each URL within the provided object with a new domain.
 *
 * This function takes an object where each key is associated with a URL string.
 * It updates the domain of each URL to the specified new domain and returns a new object
 * with the same keys but with the URLs updated.
 *
 * @param urls - A record object where keys are strings and values are URL strings.
 * @param newDomain - The new domain to replace in each URL.
 * @returns A new record object with the same keys as the input, but with URLs updated to use the new domain.
 */
export function replaceDomain(
  urls: Record<string, string>,
  newDomain: string,
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(urls).map(([key, url]) => {
      const { hostname, protocol, pathname, search, hash } = new URL(url);

      const parts = hostname.split(".");
      // Extract subdomain (everything before the last two segments)
      const subdomain = parts.slice(0, -2).join(".");
      // Build new hostname by combining subdomain with the new base domain
      const newHostname = subdomain ? `${subdomain}.${newDomain}` : newDomain;
      // Rebuild the full URL with the updated hostname
      const newUrl = `${protocol}//${newHostname}${pathname}${search}${hash}`;

      return [key, newUrl];
    })
  );
}
